/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/mira/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.css';

body {
  font-family: var(--font-family);
}

// Custom Perfo theme variables

:root {
  --surface-a: #ffffff;
  --surface-b: #f5f5f5;
  --surface-c: #edf0fA;
  --surface-d: #ebebeb;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #6c6c6c;
  --text-color-secondary: #898989;


  // check designer customization of primary colors etc
  // https://designer.primeng.org/#/
  // primary color? #55596d

  --primary-color: #26376e;
  --primary-color-text: #ffffff;
  --font-family: Poppins, sans-serif;
  --surface-0: #ffffff;
  --surface-50: #f1f3f5;
  --surface-100: #e2e6eb;
  --surface-200: #c6cdd7;
  --surface-300: #a9b4c2;
  --surface-400: #8d9bae;
  --surface-500: #70829a;
  --surface-600: #5a687b;
  --surface-700: #434e5c;
  --surface-800: #2d343e;
  --surface-900: #161a1f;
  --gray-50: #f1f3f5;
  --gray-100: #e2e6eb;
  --gray-200: #c6cdd7;
  --gray-300: #a9b4c2;
  --gray-400: #8d9bae;
  --gray-500: #70829a;
  --gray-600: #5a687b;
  --gray-700: #434e5c;
  --gray-800: #2d343e;
  --gray-900: #161a1f;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 4px;
  --surface-ground: #f5f5f5;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #ebebeb;
  --surface-hover: #edf0fA;
  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-ring: 0 0 0 0.1rem #93a4da;
  --highlight-bg: #5678b8;
  --highlight-text-color: #ffffff;
  color-scheme: light
}

.font-mono {
  font-family: 'Chivo Mono', monospace;

  // - Chivo Mono
  // Regular 400
  // Regular 400 Italic
  // Bold 700
  // Bold 700 Italic

  &.mono-italic {

  }

  &.mono-bold {

  }

  &.mono-bold-italic {

  }
}

// Overrides
$perfoBlack: #1C1C1C;
.p-sidebar {
  //background: #ffffff;
  //color: #6c6c6c;
  //border: 0 none;
  //box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: none !important;
  border-right: 2px solid #c4c4c4 !important;
  background: $perfoBlack !important;

  &.p-sidebar-left {
    width: 18rem !important;
  }

  .p-sidebar-content, .p-sidebar-header {
    padding: 0.4rem !important;
  }
}

.p-datatable {
  .p-datatable-thead > tr > th {
    padding: 0.8rem 0.7rem !important;
  }

  .p-datatable-tbody {
    > tr {
      &:focus {
        outline: none !important;
      }

      &.p-highlight {
        background: rgb(180 199 225 / 40%) !important;
      }

      > td {
        padding: 0.6rem 0.5rem !important;
      }
    }
  }
}

.p-dialog .p-dialog-content {
  padding: 0 1rem 0.2rem 1rem;
}

.p-dialog-header {
  background: $perfoBlack !important;
  padding: 1rem 1.5rem;

  .p-dialog-header-icon:enabled:hover {
    background: unset !important;
  }

  .p-dialog-title {
    color: white;
  }
}

.p-paginator {
  padding: 0.3rem 1rem !important;
}

.p-inputtext {
  &.p-component {
    padding: 0.3rem 0.6rem;
  }
}
.p-input-icon-left .p-inputtext {
  &.p-component {
    padding-left: 2rem;
  }
}

.p-toolbar {
  border: none !important;
}

.p-panel {
  $perfoPanelBg: #ECEFF4;

  .p-panel-header {
    padding: 0.7rem 1rem;

    // Match table header bg
    background: $perfoPanelBg !important;
  }

  .p-panel-content {
    border-width: 3px !important;
    border-color: $perfoPanelBg !important;
    // TODO: Refactor: Check if this panel-content style should only be applied to the deliveryDragging view, and not globally.
    padding-bottom: 0.5rem !important;
  }
}

.p-toast {
  opacity: 1;

  .p-toast-summary {
    text-transform: uppercase;
  }

  .p-toast-detail {
  }
}

// p-picklist

.p-picklist-item:not(.cdk-drag-disabled) {
  cursor: grab;
}

.p-picklist-list-wrapper {
  .p-picklist-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 0;
    border: 0;
  }

  ul li {
    border: 2px solid #5e81ac;
    border-radius: 2px;
    padding: 0 !important;
    margin-bottom: 1px;
    min-width: 353px;
    margin-right: 5px;
  }
}

.p-picklist .p-picklist-list {
  padding: 0;
}

.p-picklist {
  .p-picklist-transfer-buttons {
    button:nth-child(3),
    button:nth-child(4) {
      display: none;
    }
  }
}

// p-panel

p-panel.panel-planning-other-group {
  .p-panel-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    column-gap: 10px;

    prf-delivery-planning-item {
      width: 300px;
    }
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #1c1c1c;
  color: white;
}

////////// Perfo
.prf-field {
  margin-bottom: 12px;

  &.field-small {
    flex-basis: 130px;
  }

  &.field-medium {
    flex-basis: 260px;
  }
}

.col-data-details {
  border: 2px solid #99a4bd;
  width: 720px;
  min-width: 720px;
  background: white;
  position: fixed;
  right: 0;
  height: calc(100% - 90px);
  box-shadow: -4px 40px 51px 1px rgba(0,0,0,0.2);
  transition: 0.15s width ease-out;
}

.delivery-panel-title-count {
  color: #a7acb4;
}

fieldset {
  position: relative;
  border: 1px solid #9dafc9;
  border-radius: 4px;
  margin-bottom: 1rem; // Extract/use global var.

  legend {
    padding: 0 8px;
    margin-bottom: 0.5rem;
  }

  .disabled-fieldset {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    left: 0;
    top: 0;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    z-index: 999;
  }

}

$disabledInputBg: #7B90AB21;
.p-dropdown {
  &.p-disabled {
    .p-inputtext {
      background: $disabledInputBg;
      border-color: transparent;
    }
  }
}
.p-inputtext {
  &.p-disabled, &[disabled] {
    background: $disabledInputBg;
    border-color: transparent;
  }
}

//

.button-container-full-w {
  width: 100%;
  button {
    width: 100%;
  }
}

// Scrollbar
.p-scrollpanel .p-scrollpanel-bar {
  background: #4c566acc;
}

// Input switch
.p-inputswitch.p-inputswitch-checked {
  .p-inputswitch-slider {
    background: #5eac61;
  }
}
.p-inputswitch:not(.p-inputswitch-checked) {
  .p-inputswitch-slider {
    background: #ffa8a3;
  }
}
